// import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/es5/locale/es';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
// import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

// OECHSLE

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: colors.indigo.base,				// #3F51B5
				secondary: colors.grey.base,				// #9E9E9E
				accent: colors.lightBlue.lighten1,	// #29B6F6
				featured: colors.indigo.lighten5,		// #E8EAF6
				// error:		#FF5252
				// warning:	#FFC107
				// info:		#2196F3
				// success:	#4CAF50
			},
			// default: {
				// 	primary: colors.indigo.base,				// #3F51B5
				// 	secondary: colors.grey.base,				// #9E9E9E
				// 	accent: colors.lightBlue.lighten1,	// #29B6F6
			// },
		},
	},
	lang: {
		locales: { es },
		current: 'es',
	},
	icons: {
		iconfont: 'mdi'
	}
	
});
