export default {
	state: {
		isDemo: false,
		folder: "",
		ui: 1,
		id: 1,
		name: "Oechsle",
		fullname: "Oechsle S.A.",
		phone: "0351 489.0900",
		email: "atclientes@oechsle.com.ar",
		whatsapp: "5493515574449",
		fiscalkey: "30542942807",
		address: "Dr. Eliseo Cantón 1870 - Bº Villa Páez",
		city: "Córdoba",
		state: "Córdoba",
		zip_code: "5003",
		open_hours: [
			{day: 'Lunes', from: '8:00', to: '17:30' },
			{day: 'Martes', from: '8:00', to: '17:30' },
			{day: 'Miércoles', from: '8:00', to: '17:30' },
			{day: 'Jueves', from: '8:00', to: '17:30' },
			{day: 'Viernes', from: '8:00', to: '17:30' },
			{day: 'Sábado', from: null, to: null },
			{day: 'Domingo', from: null, to: null },
		],
		isGuestEnabled: false, // Habilito acceso a invitados (usuarios no registrados)
		enablePayments: false, // Habilita modulo de pagos para enviar el pedido.
		enableSurveys: true, // Habilita las encuestas en la app.
		orderMinimumAmount: 10000, // Mínimo para compras. TODO: esto debería obtenerse desde una API.
		freeDeliveryFrom: 10000,
		shipmentAmount: 3800,
		showStockIconOnOrder: true,

		default_showTable: false, // modo de vista por defecto al iniciar por primera vez
		default_sendTo: 2,
		
		bankData: [
			// { name: "CÓRDOBA", branch: "919", cbu: "0200919701000000666465", accountNumber: "919-6664/06", alias: "OECHSLE.CORDOBA" },
			{ name: "GALICIA", branch: "199", cbu: "0070199620000003496853", accountNumber: "3496-8 199-5", alias: "OECHSLE.GALICIA" },
			{ name: "MACRO", branch: "301", cbu: "2850301930000010848282", accountNumber: "3-301-00001084828", alias: "OECHSLE.MACRO" },
			// { name: "NACIÓN", branch: "9202", cbu: "0110152820015200079448", accountNumber: "152-00079/44", alias: "OECHSLENACION (sin punto)" },
			{ name: "PATAGONIA", branch: "56", cbu: "0340056200560006565009", accountNumber: "560006565", alias: "OECHSLE.PATAGONIA" },
			{ name: "SANTANDER", branch: "66", cbu: "0720066320000000128168", accountNumber: "066-001281/6", alias: "OECHSLE.SANTANDER" }
		],

		bankFaceToFace: [
			{name: "MACRO", type: "Recaudación", number: "Convenio 74024 + CUIT de cliente", terminal: "SI (efectivo y cheque al día)", cashier: "SI (efectivo y cheque al día"},
			{name: "GALICIA", type: "Cobranza integrada", number: "Convenio 3434 + CUIT de cliente", terminal: "SI (efectivo y cheque al día)", cashier: "SI (efectivo y cheque al día)"},
		],
		bankFaceToFaceAdvice: [
			{name: "PATAGONIA", type: "Cuenta Corriente", number: "056-56006565-000", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"},
			{name: "SANTANDER", type: "Cuenta Corriente", number: "066-001281/6", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"},
			{name: "NACIÓN", type: "Cuenta Corriente", number: "152-00079/44", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"},
			{name: "CÓRDOBA", type: "Cuenta Corriente", number: "919-6664/6", terminal: "SI (sólo efectivo)", cashier: "SI (sólo efectivo)"}
		],

		menuLinks: [
			// agregar un 0 en el array hideForRole para quitar la opción del menú a todos los roles
			{ text: "Home", icon: "mdi-home", route: "/", hideForRole: [] },
			{ text: "Productos", icon: "mdi-view-dashboard", route: "/productos", hideForRole: [] },
			{ text: "Aplicaciones", icon: "mdi-puzzle", route: "/aplicaciones", hideForRole: [] },
			{ text: "Mi Carrito", icon: "mdi-cart", route: "/carrito", hideForRole: [4, 9] },
			{ text: "Mis Pedidos", icon: "mdi-cart-check", route: "/pedidos", hideForRole: [4, 9] },
			{ text: "Novedades", icon: "mdi-newspaper-variant", route: "/novedades", hideForRole: [0] },
			{ text: "Folletos Digitales", icon: "mdi-star", route: "/flyers", hideForRole: [4, 9] },
			{ text: "Catálogos", icon: "mdi-book-open-variant", route: "/catalogos", hideForRole: [2, 4, 9] },
			{ text: "Descargas", icon: "mdi-download", route: "/descargas", hideForRole: [2, 4, 9] },
			{
				text: "Autogestión",
				icon: "mdi-file-document",
				hideForRole: [2, 4, 9],
				children: [
					// { text: "Cuenta",							icon: "mdi-file-check",						route: "/resumen-cuenta", hideForRole: [2,9] },
					// { text: "Comprobantes",				icon: "mdi-file-download",				route: "/comprobantes", hideForRole: [2,9] },
					{ text: "Estado de Cuenta", icon: "mdi-file-check", route: "/comprobantes", hideForRole: [2, 4, 9] },
					{ text: "Aviso de pago", icon: "mdi-message-star", route: "/aviso-pago", hideForRole: [2, 4, 9] },
				],
			},
			{
				text: "Configuración",
				icon: "mdi-cog",
				hideForRole: [9],
				children: [
					{ text: "Descuentos", icon: "mdi-ticket-percent", route: "/cfg-descuentos", hideForRole: [2, 4, 9] },
					{ text: "Utilidades", icon: "mdi-sack-percent", route: "/cfg-utilidades", hideForRole: [2, 4, 9] },
					{ text: "Mi cuenta", icon: "mdi-account", route: "/cfg-cuenta", hideForRole: [9] },
				],
			},
			// { text: "Contacto",								icon: "mdi-email",								route: "/contacto", hideForRole: [1,2,9]},
		],

	},

	mutations: {
		// SHIPMENT_AMOUNT
		setShipmentAmount(state, newValue) {
			state.shipmentAmount = newValue;			
		},

		// FREE_SHIPPING_FROM
		setFreeShippingFrom(state, newValue) {
			state.freeDeliveryFrom = newValue;
		},

		// ORDER_MINIMUM_AMOUNT
		setOrderMinimumAmount(state, newValue) {
			state.orderMinimumAmount = newValue;
		}


	},
	
		
};